import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_unicefbelgique',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_1',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.logo')
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'logo',
          settings: {
            hide_label: true,
            crop: false,
            size: { width: 9999, height: 95 }
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_2',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.address')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'address',
          settings: {
            hide_label: true,
            layout: 'inline'
          },
          content: {
            btn_label: i18n.getLocalesTranslations('literal.add_new_line')
          },
          components: [
            {
              path: 'modules/pages/SectionInputText',
              model: 'text',
              content: {
                label: i18n.getLocalesTranslations('literal.text')
              },
              settings: {
                hide_label: true
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_3',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.contact')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'contact',
          settings: {
            hide_label: true
          },
          content: {
            btn_label: i18n.getLocalesTranslations('literal.add_new_line'),
            wrapper_title: 'value',
            default_wrapper_title: i18n.getLocalesTranslations('literal.text')
          },
          components: [
            {
              path: 'modules/pages/SectionSelect',
              model: 'type',
              value: 'text',
              content: {
                label: i18n.getLocalesTranslations('literal.type')
              },
              settings: {
                options: [
                  { value: 'text', label: i18n.getLocalesTranslations('literal.text') },
                  { value: 'email', label: i18n.getLocalesTranslations('literal.email') },
                  { value: 'phone', label: i18n.getLocalesTranslations('literal.phone') },
                  { value: 'website', label: i18n.getLocalesTranslations('literal.website') }
                ]
              }
            },
            {
              path: 'modules/pages/SectionInputText',
              model: 'prefix',
              content: {
                label: {
                  'fr-FR': 'Préfixe',
                  'nl-NL': 'Voorvoegsel',
                  'en-GB': 'Prefix'
                }
              }
            },
            {
              path: 'modules/pages/SectionInputText',
              model: 'text',
              content: {
                label: i18n.getLocalesTranslations('literal.text')
              }
            },
            {
              path: 'modules/pages/SectionInputText',
              model: 'value',
              content: {
                label: i18n.getLocalesTranslations('literal.value')
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_4',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.bank_accounts')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'bank_accounts',
          settings: {
            hide_label: true
          },
          content: {
            btn_label: i18n.getLocalesTranslations('literal.add_new_line'),
            wrapper_title: 'value',
            default_wrapper_title: i18n.getLocalesTranslations('literal.text')
          },
          components: [
            {
              path: 'modules/pages/SectionInputText',
              model: 'prefix',
              content: {
                label: {
                  'fr-FR': 'Préfixe',
                  'nl-NL': 'Voorvoegsel',
                  'en-GB': 'Prefix'
                }
              }
            },
            {
              path: 'modules/pages/SectionInputText',
              model: 'value',
              content: {
                label: i18n.getLocalesTranslations('literal.text')
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_5',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.footer')
      },
      components: [
        {
          path: 'modules/pages/SectionTitle',
          content: {
            label: i18n.getLocalesTranslations('literal.social_networks')
          }
        },
        {
          path: 'modules/pages/SectionNetworks'
        },
        {
          path: 'modules/pages/SectionDivider',
          style: {
            divider: ['separator', 'separator__size--full', 'bg__color--vdw']
          }
        },
        {
          path: 'modules/pages/SectionTitle',
          content: {
            label: i18n.getLocalesTranslations('literal.navigation')
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'bottom_navigation',
          settings: {
            hide_label: true
          },
          content: {
            btn_label: i18n.getLocalesTranslations('literal.add_new_link'),
            wrapper_title: 'link.text'
          },
          components: [
            {
              path: 'modules/pages/SectionSelect',
              model: 'type',
              value: 'link',
              content: {
                label: i18n.getLocalesTranslations('literal.type')
              },
              settings: {
                options: [
                  { value: 'link', label: i18n.getLocalesTranslations('literal.link') },
                  { value: 'text', label: i18n.getLocalesTranslations('literal.text') }
                ]
              }
            },
            {
              path: 'modules/pages/SectionInputText',
              model: 'text',
              content: {
                label:  i18n.getLocalesTranslations('literal.text')
              },
              settings: {
                hide_label: true
              },
              conditions: [
                {
                  logic_operator: 'and',
                  model: 'type',
                  value: 'text',
                  comparison_operator: 'eq'
                }
              ]
            },
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              },
              conditions: [
                {
                  logic_operator: 'and',
                  model: 'type',
                  value: 'link',
                  comparison_operator: 'eq'
                }
              ]
            }
          ]
        },
        {
          path: 'modules/pages/SectionDivider',
          style: {
            divider: ['separator', 'separator__size--full', 'bg__color--vdw']
          }
        },
        {
          path: 'modules/pages/SectionTitle',
          content: {
            label: i18n.getLocalesTranslations('$procedure_section.type_legals')
          }
        },
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'logo',
          content: {
            label: i18n.getLocalesTranslations('literal.logo')
          },
          settings: {
            hide_label: true,
            crop: false,
            size: { width: 9999, height: 94 }
          }
        },

        {
          path: 'modules/pages/SectionInputUrl',
          model: 'link',
          content: {
            label:  i18n.getLocalesTranslations('literal.link')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label:  i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionTextEditor',
          model: 'text',
          settings: {
            configuration: ['bold', 'italic', 'underline', 'text_color']
          },
          content: {
            label: i18n.getLocalesTranslations('literal.text')
          }
        }
      ]
    }
  ]
}
